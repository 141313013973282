const caretHtml = '<span class="caret" aria-hidden="true"></span>';

function typeWriter(i) {
  const typewriter = document.getElementById('typewriter');
  const text = typewriter.dataset.text;
  if (i < (text.length)) {
    const currentText = text.substring(0, i+1).replaceAll("|", "<br> ");
    typewriter.innerHTML = currentText + caretHtml;

    let delay;
    if (text.charAt(i+2) === " ") {
      delay = 150;
    } else if (text.charAt(i+1) === "|") {
      delay = 750;
    } else {
      delay = 100;
    }

    setTimeout(function() {
      typeWriter(i + 1)
    }, delay);
  } else {
    setTimeout(function () {
      document.querySelector(".caret").remove();
      document.getElementById("enter").classList.remove("invisible");
    }, 1500);
  }
}

document.addEventListener('DOMContentLoaded',function(event){
  typeWriter(0);
});
